import { Fragment } from 'react'
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon, RectangleGroupIcon, CpuChipIcon } from '@heroicons/react/24/outline'
import logo from '../pi.svg';
import React from 'react'
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'



const navigationLinks = [
    { name: 'Our Services', href: '#services', current: true },
    { name: 'Why Choose Us', href: '#whyus', current: false },
    { name: 'Articles', href: '#articles', current: false },
    { name: 'Contact Us', href: '#contact', current: false },
  ]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navigation() {
  return (
    <>
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <CpuChipIcon className="h-6 w-6 text-white sm:ml-0 xl:ml-6 lg:ml-6" aria-hidden="true"></CpuChipIcon> 
                <span className="pl-4 text-white" aria-hidden="true">PI Consultancy Group</span>
                      
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigationLinks.map((item) => (
                      <AnchorLink 
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'text-white hover:bg-gray-700 hover:text-[#F1DFF3]' : 'text-white hover:bg-gray-700 hover:text-[#F1DFF3]',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )} /// text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </AnchorLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">              
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigationLinks.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'aaa text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

    </>
  )
}