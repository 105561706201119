

const Footer = ()=> {

    return (
        < div className="relative isolate overflow-hidden bg-gray-900 pt-12 pb-6 sm:pt-20 sm:pb-6">
       
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>


        <div className="mx-auto max-w-7xl px-6 lg:px-8">

        <div class="grid grid-cols-2 gap-1 pb-10 sm:pb-10">
            <div>
                <h2 className="text-4xl font-bold tracking-tight text-white sm:text-xl">Payment Institution Consultancy Group</h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                Contacts
                </p>
                <p className="text-white">
                    info@payment-institutions.eu
                </p>
                <p className="text-white">
                    +372 522 7992
                </p>
                <p className="text-white">
                  Rüütli 4 - 3, Tartu, Estonia                
                </p>
            </div>
            <div>
                <h5 className="text-xl font-bold tracking-tight text-white sm:text-xl">Our services</h5>
                <p className="text-white">
                Acquiring Payment Institution Licenses
                </p>
                <p className="text-white">
                PI Legal, AML, KYC consultancy
                </p>
                <p className="text-white">
                Payment Gateway IT Consultancy & Development
                </p>
              </div>
        </div>

        <hr className=""/>

        <div className="mx-auto max-w-2xl lg:mx-0 pt-1 pb-0 sm:pt-1 sm:pb-0">
        <p className="text-white">
                © 2023 PI Cunsultancy Group
        </p>
        </div>

        </div>
      </div>
  
        );
}

export default Footer;