import React from 'react'
import { BuildingLibraryIcon, ScaleIcon, ServerIcon } from '@heroicons/react/24/outline'
import Footer from './components/Footer'
import OurServices from './components/OurServices'
import Navigation from './components/Navigation'
import Contact from './components/Contact'
import Articles from './Aritcles'
import Dialog from './components/Dialog'

import oliver from './oliver3.jpg';

import './App.css';
import Whyus from './components/Whyus'


const links = [
  { name: 'Our Services', href: '#' },
  { name: 'Why Choose Us', href: '#' },
  { name: 'Articles', href: '#' },
  { name: 'Contact', href: '#' },
]

const features = [
  {
    name: 'Acquiring Payment Institution Licenses',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi quis commodo odio aenean sed adipiscing diam. A diam sollicitudin tempor id eu nisl nunc mi ipsum.',
    icon: BuildingLibraryIcon,
  },
  {
    name: 'Legal, AML, KYC consultancy',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi quis commodo odio aenean sed adipiscing diam..',
    icon: ScaleIcon,
  },
  {
    name: 'Technical development and consultancy',
    description:
      'Ultricies lacus sed turpis tincidunt id aliquet risus feugiat in. Orci ac auctor augue mauris augue.',
    icon: ServerIcon,
  }
]

function App() {

  return (
    <>
    {/*    

    */}
    <Navigation/>

    <div className="overflow-hidden bg-white pt-16 pb-24 sm:pt-16 sm:pb-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Connecting People and Ideas in Payment Tech</h2>
              <h2 className="text-4xl font-bold tracking-tight sm:text-6xl"> Your path to an European Payment Institution</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              We provide legal and technical consultation related to establishing an European Payment Insitution (PI).
              </p>
              <dl className="mt-10 max-w-xl space-y-2 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline"></dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={oliver}
            alt="Product screenshot"
            className="w-[45rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[34rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>

    <section id='services'>
    <OurServices/>
    </section>

    

    <section id='whyus'>
    <Whyus/>
    </section>

    <section id='articles'>
    <Articles/>
    </section>

    <section id='dia'>
    <Dialog/>  
    </section>            

    <section id='contact'>
    <Contact/>
    </section>

    <Footer/>

    </>
  );
}

export default App;
