import { Fragment } from 'react'
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../pi.svg';
import React from 'react'
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Blog3 = ()=> {

    return(
    <>
        <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <a href="/"><ArrowLeftIcon className="h-8 w-8 text-white" aria-hidden="true"/></a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">              
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
             
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    <div className="bg-gray-900 py-12 sm:py-12">

<div className="mx-auto max-w-7xl px-6 lg:px-8">
<div className="mx-auto max-w-2xl text-left">
  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl pb-4">What is PD2?</h2>
  <p className="mt-2 text-lg leading-8 text-gray-300">
    The Payment Services Directive 2 (PSD2) is a regulatory framework implemented by the European Union to enhance the security and efficiency of electronic payments within the European Economic Area. Introduced in January 2018, PSD2 aims to promote innovation, competition, and consumer protection in the financial services industry.
  </p>
  <p></p>
  <p className='mt-2 text-lg leading-8 text-gray-300'>
  One of the key provisions of PSD2 is the requirement for banks to provide third-party providers (TPPs) with access to customer account information through Application Programming Interfaces (APIs). This enables TPPs to offer new payment services and innovative financial products to consumers, ultimately fostering a more competitive and dynamic market.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  PSD2 also mandates the implementation of Strong Customer Authentication (SCA) for online transactions to reduce the risk of fraud and enhance the security of electronic payments. SCA involves the use of two or more authentication factors, such as passwords, biometrics, or one-time passcodes, to verify the identity of the payer.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Furthermore, PSD2 introduces the concept of Account Information Service Providers (AISPs) and Payment Initiation Service Providers (PISPs). AISPs aggregate account information from multiple banks, providing consumers with a comprehensive view of their financial data. PISPs, on the other hand, enable consumers to initiate payments directly from their bank accounts without the need for credit or debit cards.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Overall, PSD2 represents a significant step towards creating a more open, competitive, and secure payments ecosystem in Europe. By fostering innovation, enhancing consumer protection, and promoting competition, PSD2 is driving the digital transformation of the financial services industry and shaping the future of electronic payments.
  </p>
</div>

</div>
</div>
    </>
        
       
        );


}


export default Blog3;