import pic2 from './2.jpg';
import pic1 from './1.jpg';


const posts = [
    {
      id: 1,
      title: 'Open Banking in Europe',
      href: 'blog1',
      description:
        'Open banking in Europe has revolutionized the way people manage their finances by facilitating secure data sharing between financial institutions and third-party providers. This innovative concept has been gaining momentum across the continent, reshaping traditional banking practices and promoting financial transparency and customer empowerment.'+
        'One of the key benefits of open banking in Europe is the enhanced customer experience it offers. By allowing account holders to share their financial data with authorized third parties, such as fintech companies and other banks, individuals can access a wide range of personalized financial services and products tailored to their specific needs. This has led to an increase in competition within the financial sector, driving innovation and improving the quality of services available to consumers.'+
        'Moreover, open banking has also paved the way for the development of innovative financial products and services that would have been difficult to imagine in a closed banking system. For example, customers can now easily aggregate their financial information from multiple accounts and providers in one place, simplifying budgeting and financial planning. Additionally, the ability to securely share banking data has led to the emergence of new services like real-time payments, automated savings tools, and personalized financial advice based on individual spending habits and patterns.'+
        'From a regulatory perspective, the implementation of open banking in Europe has been guided by the Second Payment Services Directive (PSD2), which sets out requirements for banks to provide access to customer account information to third-party providers. This framework aims to promote competition, enhance security standards, and ultimately empower consumers to have more control over their financial data.'+
        'In conclusion, open banking in Europe represents a significant step forward in the evolution of the financial industry. By fostering collaboration between banks, fintech companies, and other service providers, open banking has the potential to drive innovation, improve customer experiences, and create new opportunities for businesses and consumers alike.',
      date: '',
      datetime: '2020-03-16',
      category: { title: 'Market', href: 'blog1' },
      author: {
        name: 'Oliver Matt',
        role: 'Partner',
        href: '#',
        imageUrl:
          pic1,
      },
    },
    {
      id: 2,
      title: 'Doing business in Estonia',
      href: 'blog2',
      description:
        'Doing business in Estonia offers a unique blend of digital innovation, a business-friendly environment, and a strong commitment to entrepreneurship. As one of the most digitally advanced countries in the world, Estonia has garnered global recognition for its efficient e-Government services, streamlined bureaucracy, and supportive infrastructure for startups and established businesses alike.'+
        ''+
        'Estonias digital identity system, known as e-Residency, allows entrepreneurs from anywhere in the world to establish and manage a business online, without the need for physical presence in the country. This groundbreaking initiative has attracted a diverse range of international entrepreneurs looking to benefit from Estonias favorable business climate, low bureaucracy, and access to the European Union market.'+
        ''+
        'Furthermore, Estonias flat-rate income tax system, low corporate tax rates, and well-developed digital infrastructure make it an attractive destination for business investment. The countrys commitment to innovation is evident in its thriving startup ecosystem, which has produced successful companies in sectors such as cybersecurity, fintech, and e-commerce.'+
       ''+ 
        'In addition to its digital prowess, Estonia boasts a highly skilled and educated workforce, with a strong focus on technology and innovation. The countrys strategic location as a gateway between East and West also makes it an ideal hub for companies looking to expand their presence in the European market.'+
        ''+
        'Overall, doing business in Estonia offers a wealth of opportunities for entrepreneurs and businesses seeking a dynamic and forward-thinking environment. With its digital innovations, supportive regulatory framework, and strategic positioning within Europe, Estonia continues to be a compelling choice for companies looking to establish and grow their presence in the region.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Market', href: 'blog2' },
      author: {
        name: 'Irene Cresci',
        role: 'Partner',
        href: '#',
        imageUrl: pic2,
      },
    }, 
    {
      id: 3,
      title: 'What is PD2?',
      href: 'blog3',
      description:
        'The Payment Services Directive 2 (PSD2) is a regulatory framework implemented by the European Union to enhance the security and efficiency of electronic payments within the European Economic Area. Introduced in January 2018, PSD2 aims to promote innovation, competition, and consumer protection in the financial services industry.'+
        ''+
        'One of the key provisions of PSD2 is the requirement for banks to provide third-party providers (TPPs) with access to customer account information through Application Programming Interfaces (APIs). This enables TPPs to offer new payment services and innovative financial products to consumers, ultimately fostering a more competitive and dynamic market.'+
       ''+ 
        'PSD2 also mandates the implementation of Strong Customer Authentication (SCA) for online transactions to reduce the risk of fraud and enhance the security of electronic payments. SCA involves the use of two or more authentication factors, such as passwords, biometrics, or one-time passcodes, to verify the identity of the payer.'+
        ''+
        'Furthermore, PSD2 introduces the concept of Account Information Service Providers (AISPs) and Payment Initiation Service Providers (PISPs). AISPs aggregate account information from multiple banks, providing consumers with a comprehensive view of their financial data. PISPs, on the other hand, enable consumers to initiate payments directly from their bank accounts without the need for credit or debit cards.'+
        ''+
        'Overall, PSD2 represents a significant step towards creating a more open, competitive, and secure payments ecosystem in Europe. By fostering innovation, enhancing consumer protection, and promoting competition, PSD2 is driving the digital transformation of the financial services industry and shaping the future of electronic payments.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Legal', href: 'blog3' },
      author: {
        name: 'Irene Cresci',
        role: 'Partner',
        href: '#',
        imageUrl: pic2
          ,
      },
    }, 
    {
      id: 4,
      title: 'AML & KYC in Open Banking',
      href: 'blog4',
      description:
      'In the context of the Payment Services Directive 2 (PSD2), Anti-Money Laundering (AML) processes play a crucial role in ensuring the security and integrity of electronic payments. AML regulations are designed to prevent the financial system from being used for money laundering and terrorist financing activities. Under PSD2, financial institutions and payment service providers are required to implement robust AML measures to detect and prevent suspicious transactions and activities.'+
      ''+
      'One of the key aspects of AML processes in the context of PSD2 is customer due diligence (CDD). Financial institutions and payment service providers must conduct thorough CDD procedures to verify the identity of their customers and assess the risk associated with their transactions. This includes collecting information such as customer identification documents, proof of address, and source of funds.'+
      ''+
      'Another important component of AML processes under PSD2 is transaction monitoring. Financial institutions and payment service providers are required to monitor transactions in real-time to detect any unusual or suspicious activities. This involves analyzing transaction patterns, amounts, and frequencies to identify potential money laundering or terrorist financing activities.'+
      ''+
      'Furthermore, AML processes in the context of PSD2 also involve reporting suspicious transactions to the relevant authorities. Financial institutions and payment service providers are obligated to report any suspicious activities to the Financial Intelligence Unit (FIU) or other competent authorities for further investigation.'+
      ''+
      'Overall, AML processes play a vital role in ensuring the security and integrity of electronic payments under the PSD2 directive. By implementing robust AML measures, financial institutions and payment service providers can help prevent money laundering and terrorist financing activities, safeguard the financial system, and protect consumers from financial crime.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Legal', href: 'blog4' },
      author: {
        name: 'Irene Cresci',
        role: 'Partner',
        href: '#',
        imageUrl: pic2,
      },
    },  
    {
      id: 5,
      title: 'Future of Online Payments',
      href: 'blog5',
      description:
      'The future of the online payment industry in Europe is poised for significant growth and innovation as technology continues to advance and consumer preferences evolve. With the rise of e-commerce and digital transactions, the demand for convenient, secure, and seamless payment solutions is higher than ever. In response to this demand, the online payment industry in Europe is expected to witness a surge in digital payment methods, mobile wallets, and contactless payments.'+
      ''+
      'One of the key trends shaping the future of the online payment industry in Europe is the increasing adoption of mobile payment solutions. With the widespread use of smartphones and mobile devices, consumers are increasingly turning to mobile wallets and payment apps for their transactions. This trend is expected to continue as mobile payment technologies become more sophisticated and user-friendly, offering consumers a convenient and efficient way to make payments on the go.'+
      ''+
      'Another important trend in the future of the online payment industry in Europe is the growing emphasis on security and data protection. With the implementation of regulations such as the General Data Protection Regulation (GDPR) and the Payment Services Directive 2 (PSD2), online payment providers are required to adhere to strict security standards to protect consumer data and prevent fraud. As a result, we can expect to see an increased focus on biometric authentication, tokenization, and encryption technologies to enhance the security of online transactions.'+
      ''+
      'Furthermore, the future of the online payment industry in Europe is likely to be shaped by the continued expansion of cross-border payment solutions and the integration of new payment technologies such as blockchain and cryptocurrencies. These developments have the potential to revolutionize the way payments are made and processed, offering faster, more cost-effective, and transparent payment solutions for businesses and consumers alike.'+
      ''+
      'Overall, the future of the online payment industry in Europe is bright, with exciting opportunities for growth, innovation, and collaboration among industry players. As technology continues to advance and consumer expectations evolve, we can expect to see a dynamic and competitive online payment landscape that offers a wide range of payment options and services to meet the diverse needs of consumers in Europe and beyond.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Business', href: 'blog5' },
      author: {
        name: 'Irene Cresci',
        role: 'Partner',
        href: '#',
        imageUrl: pic2,
      },
    }, 
    {
      id: 6,
      title: 'Acquiring an open banking license',
      href: 'blog6',
      description:
      'Obtaining a European Payment Institution (PI) license is a complex and rigorous process that involves meeting strict regulatory requirements set forth by the European Union. A Payment Institution is a financial entity authorized to provide payment services within the European Economic Area (EEA) under the Payment Services Directive (PSD2). To obtain a PI license, applicants must demonstrate compliance with a range of regulatory standards to ensure the safety and security of payment services for consumers.'+
      ''+
      'The first step in obtaining a PI license is to submit a comprehensive application to the relevant regulatory authority in the country where the institution intends to operate. The application must include detailed information about the business model, ownership structure, management team, risk management procedures, and compliance framework of the institution. Additionally, applicants must provide evidence of sufficient financial resources, professional indemnity insurance, and internal controls to safeguard customer funds and data.'+      ''+
      'Once the application is submitted, regulatory authorities will conduct a thorough review to assess the applicants compliance with regulatory requirements. This review process may include background checks on the institutions directors and shareholders, on-site inspections of the institutions premises, and assessments of the institutions risk management and compliance procedures.'+
      ''+
      'If the regulatory authority is satisfied with the applicants compliance with regulatory standards, they will issue a Payment Institution license, allowing the institution to provide payment services within the EEA. It is important to note that obtaining a PI license is a time-consuming and resource-intensive process that requires careful planning and preparation. However, once obtained, a PI license enables institutions to operate legally and securely within the European payments market, providing valuable services to consumers and businesses alike.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Legal', href: 'blog6' },
      author: {
        name: 'Oliver Matt',
        role: 'General Partner',
        href: '#',
        imageUrl: pic1,
      },
    }, 
  ]
  
const Articles = ()=> {
   
    return (
        <div className="bg-white py-24 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Articles</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn more about Payment Insitutions and the environment you are in.
          </p>
        </div>
          <div className="mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-4 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center gap-x-4 text-xs">
                  <a
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a
                      href={post.href}
                      data-ripple-light="true"
                      data-collapse-target="collapse"
                      >
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a>
                        <span className="absolute inset-0" />
                        {post.author.name}
                      </a>
                    </p>
                    <p className="text-gray-600">{post.author.role}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    );
}

export default Articles;