import { Fragment } from 'react'
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../pi.svg';
import React from 'react'
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Blog6 = ()=> {

    return(
    <>
        <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <a href="/"><ArrowLeftIcon className="h-8 w-8 text-white" aria-hidden="true"/></a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">              
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
             
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    <div className="bg-gray-900 py-12 sm:py-12">

<div className="mx-auto max-w-7xl px-6 lg:px-8">
<div className="mx-auto max-w-2xl text-left">
  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl pb-4">Acquiring An Open Banking License</h2>
  <p className="mt-2 text-lg leading-8 text-gray-300">

  Obtaining a European Payment Institution (PI) license is a complex and rigorous process that involves meeting strict regulatory requirements set forth by the European Union. A Payment Institution is a financial entity authorized to provide payment services within the European Economic Area (EEA) under the Payment Services Directive (PSD2). To obtain a PI license, applicants must demonstrate compliance with a range of regulatory standards to ensure the safety and security of payment services for consumers.
  
  </p>
  <p></p>
  <p className='mt-2 text-lg leading-8 text-gray-300'>
  The first step in obtaining a PI license is to submit a comprehensive application to the relevant regulatory authority in the country where the institution intends to operate. The application must include detailed information about the business model, ownership structure, management team, risk management procedures, and compliance framework of the institution. Additionally, applicants must provide evidence of sufficient financial resources, professional indemnity insurance, and internal controls to safeguard customer funds and data.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Once the application is submitted, regulatory authorities will conduct a thorough review to assess the applicants compliance with regulatory requirements. This review process may include background checks on the institutions directors and shareholders, on-site inspections of the institutions premises, and assessments of the institutions risk management and compliance procedures.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  If the regulatory authority is satisfied with the applicants compliance with regulatory standards, they will issue a Payment Institution license, allowing the institution to provide payment services within the EEA. It is important to note that obtaining a PI license is a time-consuming and resource-intensive process that requires careful planning and preparation. However, once obtained, a PI license enables institutions to operate legally and securely within the European payments market, providing valuable services to consumers and businesses alike.
  </p>

</div>

</div>
</div>
    </>
        
       
        );


}


export default Blog6;