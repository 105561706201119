import { Switch } from '@headlessui/react'
import { useEffect, useState } from 'react'
import api from './api/post';
import { CheckIcon } from '@heroicons/react/24/outline'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Contact = () => {

    const [agreed, setAgreed] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [company, setCompany] = useState();
    const [message, setMessage] = useState();
    const [messageSent, setMessageSent] = useState(false);

    const handleContactSubmit = async () => {
     
      if(messageSent) {
        return null;
      }

      const details = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        company: company,
        message: message
      }
     
      const response = await api.post('', details);

      if (response.data.result === "SUCCESS") {
        setMessageSent(true);
      } else {
        setMessageSent(true);
      }
      document.getElementById('submitButton').innerHTML = "Your message has been sent! ";
    } 

    return (
        <>

    <a id="contectform">
       <div className="isolate bg-white px-6 py-24 sm:pt-2 sm:pb-16 lg:px-8">
    <div
       className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
       aria-hidden="true"
     >
       <div
         className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
         style={{
           clipPath:
             'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
         }}
       />
     </div>      
     <div className="mx-auto max-w-2xl text-center">
       <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Let's Get in Contact</h2>
       <p className="mt-2 text-lg leading-8 text-gray-600">
         Leave us your contact details and we will get back to you.
       </p>
     </div>
     <div className="mx-auto mt-16 max-w-xl sm:mt-20">
       <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
         <div>
           <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
             First name
           </label>
           <div className="mt-2.5">
             <input
               type="text"
               name="first-name"
               id="first-name"
               autoComplete="given-name"
               onChange={(e) => setFirstName(e.target.value)}
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div>
           <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
             Last name
           </label>
           <div className="mt-2.5">
             <input
               type="text"
               name="last-name"
               id="last-name"
               autoComplete="family-name"
               onChange={(e) => setLastName(e.target.value)}
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
             Company
           </label>
           <div className="mt-2.5">
             <input
               type="text"
               name="company"
               id="company"
               autoComplete="organization"
               onChange={(e) => setCompany(e.target.value)}
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
             Email
           </label>
           <div className="mt-2.5">
             <input
               type="email"
               name="email"
               id="email"
               autoComplete="email"
               onChange={(e) => setEmail(e.target.value)}
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="phoneNumber" className="block text-sm font-semibold leading-6 text-gray-900">
             Phone number
           </label>
           <div className="mt-2.5">
             <input
               type="text"
               name="phoneNumber"
               id="phoneNumber"
               autoComplete="phone"
               onChange={(e) => setPhone(e.target.value)}
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
             />
           </div>
         </div>

         <div className="sm:col-span-2">
           <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
             Message
           </label>
           <div className="mt-2.5">
             <textarea
               name="message"
               id="message"
               rows={4}
               onChange={(e) => setMessage(e.target.value)}
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
               defaultValue={''}
             />
           </div>
         </div>

         <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
           <div className="flex h-6 items-center text-sm leading-6 text-gray-600">
           By submitting this form, you agree to our privacy policy.

           </div>
           <Switch.Label className="text-sm leading-6 text-gray-600">
            
           </Switch.Label>
         </Switch.Group>
       </div>
       <div className="mt-10">
         <button id='submitButton'
           onClick={() => handleContactSubmit()}
           className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
         >
           Let's talk
         </button>
       </div>
     </div>
   </div>
   </a>

        </>
    );

}

export default Contact;