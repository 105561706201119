const Dialog = () => {

    return(
        <>
            <dialog id="my_modal_1" className="modal w-9/12 rounded-full inline-block align-middle text-center border border-indigo-600">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Hello!</h3>
                    <span className="inline-block align-middle ... 	text-center">
                         ewwe Press ESC key or click the button below to close</span>
                </div>
                <form method="dialog" className="modal-backdrop">
    <button>close</button>
  </form>
            </dialog>
        </>
    );
}

export default Dialog;