import { BuildingLibraryIcon, ScaleIcon, ServerIcon } from '@heroicons/react/24/outline'


const features = [
    {
      name: 'Acquiring Payment Institution Licenses',
      description:
        'Are you looking to navigate the complex regulatory landscape of obtaining a European Payment Institution license? Look no further! Our expert legal consultancy service specializes in guiding businesses through the intricate process of securing a PI license in Estonia.',
      icon: BuildingLibraryIcon,
    },
    {
      name: 'PI Legal, AML & KYC consultancy',
      description:
        'With a team of experienced  professionals well-versed in financial regulations, we provide tailored advice and support to ensure your compliance with all legal, AML & KYC requirements. Trust us to streamline the application process, mitigate risks, and help you establish a solid foundation for your payment institution.',
      icon: ScaleIcon,
    },
    {
      name: 'Payment Gateway IT Consultancy & Development',
      description:
        'With a team of skilled payment experts, we offer tailored solutions to optimize your payment gateway for seamless transactions. From integration with multiple payment methods to ensuring top-notch security measures, we provide comprehensive consultancy and development services to meet your specific business needs. ',
      icon: ServerIcon,
    },
  ]


const OurServices = ()=> {

    return (
        <div className="bg-white pt-4 pb-6 sm:pt-4 sm:pb-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Over 15 years of bringing value in Payment Industry</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our Services
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            We are a consultancy company focused on bringing value through our experience and knowledge to our customers.            
            Let us handle the legal complexities while you focus on growing your business. Contact us today for expert guidance!          
            
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    );
}

export default OurServices;