import { Fragment } from 'react'
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../pi.svg';
import React from 'react'
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Blog2 = ()=> {

    return(
    <>
        <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <a href="/"><ArrowLeftIcon className="h-8 w-8 text-white" aria-hidden="true"/></a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">              
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
             
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    <div className="bg-gray-900 py-12 sm:py-12">

<div className="mx-auto max-w-7xl px-6 lg:px-8">
<div className="mx-auto max-w-2xl text-left">
  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl pb-4">Doing Business in Estonia</h2>
  <p className="mt-2 text-lg leading-8 text-gray-300">

  Doing business in Estonia offers a unique blend of digital innovation, a business-friendly environment, and a strong commitment to entrepreneurship. As one of the most digitally advanced countries in the world, Estonia has garnered global recognition for its efficient e-Government services, streamlined bureaucracy, and supportive infrastructure for startups and established businesses alike.  
  </p>
  <p></p>
  <p className='mt-2 text-lg leading-8 text-gray-300'>
  Estonia's digital identity system, known as e-Residency, allows entrepreneurs from anywhere in the world to establish and manage a business online, without the need for physical presence in the country. This groundbreaking initiative has attracted a diverse range of international entrepreneurs looking to benefit from Estonia's favorable business climate, low bureaucracy, and access to the European Union market.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Furthermore, Estonia's flat-rate income tax system, low corporate tax rates, and well-developed digital infrastructure make it an attractive destination for business investment. The countrys commitment to innovation is evident in its thriving startup ecosystem, which has produced successful companies in sectors such as cybersecurity, fintech, and e-commerce.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  In addition to its digital prowess, Estonia boasts a highly skilled and educated workforce, with a strong focus on technology and innovation. The countrys strategic location as a gateway between East and West also makes it an ideal hub for companies looking to expand their presence in the European market.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Overall, doing business in Estonia offers a wealth of opportunities for entrepreneurs and businesses seeking a dynamic and forward-thinking environment. With its digital innovations, supportive regulatory framework, and strategic positioning within Europe, Estonia continues to be a compelling choice for companies looking to establish and grow their presence in the region.
  </p>
</div>

</div>
</div>
    </>
        
       
        );


}


export default Blog2;