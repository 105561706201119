import { Fragment } from 'react'
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../pi.svg';
import React from 'react'
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Blog4 = ()=> {

    return(
    <>
        <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <a href="/"><ArrowLeftIcon className="h-8 w-8 text-white" aria-hidden="true"/></a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">              
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
             
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    <div className="bg-gray-900 py-12 sm:py-12">

<div className="mx-auto max-w-7xl px-6 lg:px-8">
<div className="mx-auto max-w-2xl text-left">
  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl pb-4">AML & KYC in Open Banking</h2>
  <p className="mt-2 text-lg leading-8 text-gray-300">
  In the context of the Payment Services Directive 2 (PSD2), Anti-Money Laundering (AML) processes play a crucial role in ensuring the security and integrity of electronic payments. AML regulations are designed to prevent the financial system from being used for money laundering and terrorist financing activities. Under PSD2, financial institutions and payment service providers are required to implement robust AML measures to detect and prevent suspicious transactions and activities.
  </p>
  <p></p>
  <p className='mt-2 text-lg leading-8 text-gray-300'>
  One of the key aspects of AML processes in the context of PSD2 is customer due diligence (CDD). Financial institutions and payment service providers must conduct thorough CDD procedures to verify the identity of their customers and assess the risk associated with their transactions. This includes collecting information such as customer identification documents, proof of address, and source of funds.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Another important component of AML processes under PSD2 is transaction monitoring. Financial institutions and payment service providers are required to monitor transactions in real-time to detect any unusual or suspicious activities. This involves analyzing transaction patterns, amounts, and frequencies to identify potential money laundering or terrorist financing activities.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Furthermore, AML processes in the context of PSD2 also involve reporting suspicious transactions to the relevant authorities. Financial institutions and payment service providers are obligated to report any suspicious activities to the Financial Intelligence Unit (FIU) or other competent authorities for further investigation.
  </p>
  <p className='mt-3 text-lg leading-8 text-gray-300'>
  Overall, AML processes play a vital role in ensuring the security and integrity of electronic payments under the PSD2 directive. By implementing robust AML measures, financial institutions and payment service providers can help prevent money laundering and terrorist financing activities, safeguard the financial system, and protect consumers from financial crime.
  </p>
</div>

</div>
</div>
    </>
        
       
        );


}


export default Blog4;